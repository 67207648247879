@font-face
    font-family: 'ue-icomoon'
    src:  url('./fonts/iconmoon/icomoon.eot?ve6693')
    src:  url('./fonts/iconmoon/icomoon.eot?ve6693#iefix') format('embedded-opentype'), url('./fonts/iconmoon/icomoon.ttf?ve6693') format('truetype'), url('./fonts/iconmoon/icomoon.woff?ve6693') format('woff'), url('./fonts/iconmoon/icomoon.svg?ve6693#icomoon') format('svg')
    font-style: normal
    font-weight: normal

.ue-react
  [class^="icon-"], [class*=" icon-"]
      font-family: 'ue-icomoon' !important
      speak: none
      font-style: normal
      font-weight: normal
      font-variant: normal
      text-transform: none
      line-height: 1
      -webkit-font-smoothing: antialiased
      -moz-osx-font-smoothing: grayscale

  .icon-touch2:before
    content: "\e915"

  .icon-touch:before
    content: "\e914"

  .icon-reddit:before
    content: "\e911"

  .icon-wechat:before
    content: "\e912"

  .icon-vk:before
    content: "\e913"

  .icon-checkmark:before
    content: "\ea10"

  .icon-enlarge:before
    content: "\e989"

  .icon-shrink:before
    content: "\e98a"

  .icon-plus:before
    content: "\ea0a"

  .icon-minus:before
    content: "\ea0b"

  .icon-play2:before
    content: "\ea15"

  .icon-quote:before
    content: "\e978"

  .icon-quote-open:before
    content: "\e977"

  .icon-facebook:before
    content: "\ea90"

  .icon-twitter:before
    content: "\e900"

  .icon-weibo:before
    content: "\ea9a"

  .icon-tumblr:before
    content: "\eab9"

  .icon-linkedin:before
    content: "\eaca"

  .icon-help:before
    content: "\e90e"

  .icon-link:before
    content: "\e9cb"

  .icon-download:before
    content: "\e90b"

  .icon-chevron-down:before
    content: "\e901"
    color: #888

  .icon-chevron-left:before
    content: "\e902"
    color: #888

  .icon-chevron-right:before
    content: "\e903"
    color: #888

  .icon-chevron-up:before
    content: "\e904"
    color: #888

  .icon-cross:before
    content: "\ea0f"
    color: #8d8d8d

  .icon-globe:before
    content: "\e906"
    color: #ccc

  .icon-search:before
    content: "\e907"
    color: #ccc

  .icon-arrow-left:before
    content: "\e910"

  .icon-chevron:before
    content: "\e90f"

  .icon-arrow-right:before
    content: "\e90d"

  .icon-play:before
    content: "\e90c"

  .icon-ue-full:before
    content: "\e90a"

  .icon-ue:before
    content: "\e909"

  .icon-share:before
    content: "\ea7d"

  .icon-new-tab:before
    content: "\ea7e"
